import gsap from "gsap";
// get other plugins:
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)
const fadeInEls = gsap.utils.toArray('.js-fadein-effect1');
if (fadeInEls.length != 0) {
  fadeInEls.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 80,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      duration: 1.25,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}
const effec1 = gsap.utils.toArray('.js-effect1');
if (effec1.length != 0) {
  effec1.forEach((box, index) => {
    gsap.set(box, {'clip-path': 'inset(0 0 100% 0)'});
    gsap.to(box, {
      'clip-path': 'inset(0% 0 0% 0%)', duration: 1, stagger: 1,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}